@import '../../../styles/customMediaQueries.css';

.contentWidth {
  composes: contentWidth from global;
}

.sectionPadding {
  composes: sectionPadding from global;
}

.sectionHead {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }

  & h2 {
    font-weight: var(--fontWeightBold);
    letter-spacing: 0em;
    color: var(--colorGrey900);
    margin: 0 0 14px 0;
    padding: 0;
    font-size: 24px;
    line-height: 130%;

    @media (--viewportSmall) {
      font-size: 26px;
    }

    @media (--viewportMedium) {
      font-size: 28px;
    }

    @media (--viewportLarge) {
      font-size: 32px;
    }
  }

  & p {
    font-size: 16px;
    font-weight: var(--fontWeightMedium);
    line-height: 150%;
    letter-spacing: 0em;
    color: var(--colorGrey700);
    margin: 5px 0px;
    padding: 0;

    @media (--viewportSmall) {
      font-size: 18px;
    }
  }

  & > a {
    display: inline-block;
    color: var(--primaryColor);
    padding: 0px;
    transition: var(--transitionStyle);
    text-decoration: underline;
    font-size: 16px;
    line-height: 120%;
    font-weight: var(--fontWeightSemiBold);

    &:hover {
      transition: var(--transitionStyle);
      color: var(--marketplaceColorDark);
      text-decoration: underline;

      & > svg {
        & > path {
          fill: var(--marketplaceColorDark);
        }
      }
    }

    & > svg {
      margin-left: 2px;
      fill: none;
      width: 18px;
      height: 18px;
    }
  }
}

.tournamentSec {
  background-color: var(--colorWhite);

  & .contentWidth {
    display: flex;
    flex-direction: column;
  }

  & .sectionContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    text-align: center;

    @media (--viewportSmall) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (--viewportMedium) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  & .tournamentContent {
    & .tournamentImg {
      position: relative;

      & > img {
        max-width: 100%;
      }
    }

    & .tournamentInfo {
      & > h4 {
        color: var(--colorGrey900);
        margin: 0 0 14px 0;
      }
    }
  }
}

.aboutTournaZoneSec {
  position: relative;
  padding-bottom: 56.25%;
  margin-top: 14px;

  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.tournamentRegisterSec {
  & .contentWidth {
    display: flex;
    flex-direction: column;
  }

  & .sectionContent {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 24px;

    @media (--viewportSmall) {
      grid-template-columns: repeat(1, 1fr);
    }

    @media (--viewportMedium) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  & .tournamentContent {
    & .tournamentImg {
      position: relative;

      & > img {
        max-width: 100%;
      }
    }

    & .tournamentInfo {
      display: flex;
      flex-direction: column;

      & > h4 {
        color: var(--colorGrey900);
        margin: 10px 0;
      }

      & > p {
        line-height: 160%;
        color: var(--colorGrey600);
      }
    }
  }
}

.freeRegistrationSec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  & > button {
    width: auto;
    margin: 0 auto;
  }
}

.tradingPlatformSec {
  background-image: url('https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/4e/1d8e8bc3b768e6c4041a0c18b898bba81beba3?auto=format&fit=clip&h=2400&w=2400&s=b828bf9c77764d7327b9bf0b553b72dd');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  & .contentWidth {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    @media (--viewportMedium) {
      flex-direction: row;
      gap: 64px;
    }

    & .tradingInfo {
      width: 100%;
      order: 2;

      @media (--viewportMedium) {
        width: 50%;
        order: 1;
      }

      & h4 {
        color: var(--colorGrey900);
      }

      & .link {
        width: auto;
        margin-top: 24px;
      }
    }

    & .tradingImg {
      width: 100%;
      border-radius: var(--borderRadiusLarge);
      overflow: hidden;
      order: 1;
      padding-bottom: 66%;
      position: relative;

      @media (--viewportMedium) {
        padding-bottom: 40%;
        width: 50%;
        order: 2;
      }

      & > img {
        max-width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.tournamentInfo {
  & span {
    cursor: pointer;
    color: var(--marketplaceColor);
  }
}
