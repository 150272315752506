@import '../../../styles/customMediaQueries.css';

.heroContainer {
  background-image: url('https://sharetribe-assets.imgix.net/6565a2b4-7a23-4278-841c-7684a015c012/raw/8c/5264e5215ff86641c73429d1d8763701d44327?auto=format&fit=clip&h=2400&w=2400&s=3b451f73e56ad55f67621cb45d8d9adb');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  position: relative;
  min-height: 60vh;
  width: 100%;
  @media (--viewportMedium) {
    min-height: 90vh;
  }
  & .heroBg {
    background-position: 78%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 50px 24px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    min-height: 60vh;
    width: 100%;
    @media (max-width: 768px) {
      padding: 24px 24px;
    }
    @media (--viewportMedium) {
      min-height: 90vh;
      background-position: center;
    }
    &::before {
      position: absolute;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      content: '';
      left: 0;
      top: 0;
    }
  }
  & .contentWidth {
    position: relative;
    z-index: 1;
    max-width: 1050px;
    width: 100%;
    margin: 0px auto;
    & .heroContent {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      & > h1 {
        color: var(--colorWhite);
        margin: 0px 0 10px 0;
        padding: 0;
      }
      & p {
        color: var(--colorGrey50);
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 130%;
        letter-spacing: 0em;
        text-align: center;
        @media (--viewportMedium) {
          margin: 10px 0 0 0;
          font-size: 18px;
        }
      }
    }
  }
}
.heroSearchSec {
  width: 100%;
  margin: 48px 0 0;

  @media (max-width: 768px) {
    margin: 12px 0 0;
  }

  & .landingSearchForm {
    background-color: rgba(250, 250, 250, 0.77);
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    @media (max-width: 768px) {
      padding: 8px;
    }

    border-radius: var(--borderRadiusLarge);
    box-shadow: var(--boxShadowListingCard);
  }
  & :global(.DateRangePickerInput) {
    width: 100%;
  }
  & :global(.DateRangePicker_picker) {
    width: 100%;
    /* top: 0 !important; */
  }
  & :global(.DateRangePicker) {
    width: 100%;
    right: 0;
    bottom: 0;
  }
  & :global(.CalendarMonthGrid__vertical) {
    width: 100% !important;
  }
  & :global(.CalendarMonth_table) {
    width: 100%;
  }
  & :global(.DayPickerNavigation) {
    padding: 0;
  }
  & :global(.DayPickerNavigation_button) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    & > svg {
      width: 20px;
      height: 20px;
    }
  }
  & :global(.DayPicker_weekHeader) {
    width: 100%;
    margin: 0 !important;
    left: 0;
  }
  & :global(.DayPicker_weekHeader_ul) {
    width: 100%;
  }
  & :global(.DayPicker_weekHeader_li) {
    width: calc(100% / 7) !important;
  }
  & :global(.CalendarDay) {
    width: calc(100% / 7) !important;
    height: 30px !important;
  }
  & :global(.CalendarMonthGrid_month__hideForAnimation + div + div .CalendarMonth_caption) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
.title {
  composes: h1 from global;
}

.sectionSubHeading {
  composes: sectionSubHeading from global;
}
